import { useMemo, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import generatePointOfContactForm from '../../../../requisitions-and-orders/change-sets/DeliveryAddress/address-component/point-of-contact-form';
import { ComboBoxControl, ReadonlyModifier } from '@gsa/afp-shared-form-utils';
import useLeasingNavigation from '../../../../../hooks/useLeasingNavigation';
import { LeasingSteps } from '../../../leasing-consts';
import { getPhoneNumberCountryCode } from '../../../../../utilities/commonUtils';


export default function generateMarshallingAddressForm({
    marshallingAddress,
    zones,
    defaultStates,
    showSkippedAlert,
    setShowSkippedAlert,
    fleetManagementCenters,
    formRef,
    marshallerLocations,
    createdByUser,
    countryCodeOptions,
}) {
    const { getUrlForStep } = useLeasingNavigation();

    const defaultValues = useMemo(() => ({
        marshallingVendorId: marshallingAddress?.deliveryAddress?.marshallingVendorId || '',
        zone: marshallingAddress?.deliveryAddress?.zone || '-1',
        state: marshallingAddress?.deliveryAddress?.state ||'-1',
        location: marshallingAddress?.deliveryAddress?.location ||'-1',
        marshallingAddress: {
            location: marshallingAddress?.deliveryAddress?.marshallingAddress?.location || '',
            deliveryAddress: {
                title: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.title || '',
                address1: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.address1 || '',
                address2: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.address2 || '',
                address3: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.address3 || '',
                city: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.city || '',
                state: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.state || '',
                zip: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.zip || '',
                country: marshallingAddress?.deliveryAddress?.marshallingAddress?.deliveryAddress?.country || '',
            },
            pointOfContact: {
                firstName: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.firstName || '',
                lastName: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.lastName || '',
                email: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.email || '',
                phone: {
                    countryCode: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.phone?.countryCode || '',
                    number: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.phone?.number || '',
                    extension: marshallingAddress?.deliveryAddress?.marshallingAddress?.pointOfContact?.phone?.extension || '',
                },
            },
            telematicsInstaller: marshallingAddress?.deliveryAddress?.marshallingAddress?.telematicsInstaller || '',
            acceptingElectricVehicles: marshallingAddress?.deliveryAddress?.marshallingAddress?.acceptingElectricVehicles || '',
        },
        licenseDelivery: marshallingAddress?.deliveryAddress?.licenseDelivery ||'',
        fmcLocation: marshallingAddress?.deliveryAddress?.fmcLocation.toString() ||'-1',
        fieldOffice: marshallingAddress?.deliveryAddress?.fieldOffice?.toString() || '-1',
        fmcOfficeLocationDetails: {
            location: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.location ||'',
            fieldOffice: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOffice || '',
            deliveryAddress: {
                address1: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.address1 ||'',
                address2: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.address2 ||'',
                address3: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.address3 ||'',
                city: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.city ||'',
                state: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.state ||'',
                zip: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress?.zip ||'',
            },
            fieldOfficeDeliveryAddress: {
                address1: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address1 || '',
                address2: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address2 || '',
                address3: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address3 || '',
                city: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.city || '',
                state: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.state || '',
                zip: marshallingAddress?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.zip || '',
            },
        },
        licenseDeliverPoC: {
            firstName: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.firstName || createdByUser?.firstName || '',
            lastName: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.lastName || createdByUser?.lastName || '',
            email: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.email || createdByUser?.email || '',
            phone: {
                countryCode: getPhoneNumberCountryCode(marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.phone?.countryCode?.split(':')[0] || createdByUser?.phoneNumberCountryCode?.split(':')[0] || ''),
                number: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.phone?.number || createdByUser?.phoneNumber || '',
                extension: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.phone?.extension || createdByUser?.phoneNumberExtension || '',
            },
            fax: {
                countryCode: getPhoneNumberCountryCode(marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.fax?.countryCode?.split(':')[0] || ''),
                number: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.fax?.number || '',
                extension: marshallingAddress?.deliveryAddress?.licenseDeliverPoC?.fax?.extension || '',
            }
        }
    }), [createdByUser, marshallingAddress]);

    return [defaultValues, {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        gap: '80px',
        sections: [
            {
                gap: '40px',
                sections: [
                    {
                        header: "Delivery Address",
                        element: {
                            header: {
                                style: {
                                    color: '#005EA2',
                                    fontSize: '16px',
                                    fontWeight: '900',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    borderBottom: '1px solid #DFE1E2',
                                    marginBottom: '20px',
                                }
                            }
                        },
                        fields: [
                            {
                                label: "Which marshalling location will the vehicle(s) be delivered to",
                                required: true,
                                component: () => {
                                    const [showAlert, setShowAlert] = useState(true);

                                    if (!showAlert) return <></>;

                                    return (
                                        <Alert
                                            type='info'
                                            onClose={() => setShowAlert(false)}
                                        >
                                            You may also select the zone and state below, then choose from a list of available marshalling locations for delivery. If you’d like to change your delivery option selection, please revisit the <Link to={getUrlForStep(LeasingSteps.COMPARE_AND_SELECT)}>Compare and Select</Link> page prior to order placement.
                                        </Alert>
                                    )
                                }
                            },
                            {
                                label: 'Zone',
                                id: 'zone',
                                type: 'select',
                                options: [
                                    { label: '-select-', value: '-1' },
                                    ...zones,
                                ]
                            },
                            {
                                label: 'State',
                                id: 'state',
                                type: 'select',
                                options: [
                                    { label: '-select-', value: '-1' },
                                    ...defaultStates || [],
                                ]
                            },
                            {
                                label: 'Marshalling locations for delivery',
                                id: 'location',
                                defaultValue: marshallingAddress?.deliveryAddress?.location ||'-1',
                                required: true,
                                component: (props, useFormContext) => {
                                    const { watch, setValue, getValues } = useFormContext();
                                    const [zone, state] = watch(['zone', 'state']);
                                    const [locations, setLocations] = useState([
                                        { label: '-select-', value: '-1' },
                                        ...marshallerLocations?.map(location => ({
                                            label: [
                                                location?.vendor_name,
                                                location?.city,
                                                location?.state_code,
                                                location?.is_telematics_installer ? 'TLM' : '',
                                                location?.accept_zev ? 'EV' : '',
                                            ].filter(Boolean).join(', '),
                                            value: location.vendor_marshaller_location_id,
                                        })).sort((a, b) => a.label.localeCompare(b.label))
                                    ]);

                                    // whenver the zone changes
                                    useMemo(() => {
                                        let locations = structuredClone(marshallerLocations);

                                        if (state && state !== '-1') {
                                            locations = locations.filter(location => location?.state_code === state);
                                        }

                                        if (zone && zone !== '-1') {
                                            locations = locations.filter(location => location.zone_id == zone);
                                        }

                                        locations = locations.map(location => ({
                                            label: [
                                                location?.vendor_name,
                                                location?.city,
                                                location?.state_code,
                                                location?.is_telematics_installer ? 'TLM' : '',
                                                location?.accept_zev ? 'EV' : '',
                                            ].filter(Boolean).join(', '),
                                            value: location.vendor_marshaller_location_id,
                                        }))
                                            .sort((a, b) => a.label.localeCompare(b.label))

                                        let selectedLocationId = getValues('location');
                                        let selectedLocationInOptions = locations.find(location => location.value === selectedLocationId);
                                        if (!selectedLocationInOptions) {
                                            setValue('location', '-1');
                                            setValue('licenseDelivery', '');
                                            setValue('fmcLocation', '-1');
                                            setValue('marshallingVendorId', '');
                                            setValue('marshallingAddress', {
                                                location: '',
                                                deliveryAddress: {
                                                    title: '',
                                                    street: '',
                                                    city: '',
                                                    state: '',
                                                    zip: '',
                                                    country: '',
                                                },
                                                pointOfContact: {
                                                    firstName: '',
                                                    lastName: '',
                                                    email: '',
                                                    phone: {
                                                        countryCode: '',
                                                        number: '',
                                                        extension: '',
                                                    }
                                                },
                                                telematicsInstaller: '',
                                                acceptingElectricVehicles: '',
                                            });
                                        }

                                        setLocations([
                                            { label: '-select-', value: '-1' },
                                            ...locations
                                        ]);
                                    }, [zone, state, marshallerLocations])


                                    return ComboBoxControl(
                                        {
                                            ...props,
                                            className: [],
                                        },
                                        {
                                            ...props,
                                            options: locations,
                                            element: {
                                                control: {
                                                    onChange: (value) => {
                                                        setValue('licenseDelivery', '');
                                                        setValue('fmcLocation', '-1');
                                                        if (!value) {
                                                            setValue('location', '-1');
                                                        }

                                                        if (!value || value === '-1') {
                                                            setValue('marshallingVendorId', '');
                                                            setValue('marshallingAddress', {
                                                                location: '',
                                                                deliveryAddress: {
                                                                    title: '',
                                                                    address1: '',
                                                                    address2: '',
                                                                    address3: '',
                                                                    city: '',
                                                                    state: '',
                                                                    zip: '',
                                                                    country: '',
                                                                },
                                                                pointOfContact: {
                                                                    firstName: '',
                                                                    lastName: '',
                                                                    email: '',
                                                                    phone: {
                                                                        countryCode: '',
                                                                        number: '',
                                                                        extension: '',
                                                                    }
                                                                },
                                                                telematicsInstaller: '',
                                                                acceptingElectricVehicles: '',
                                                            }, {
                                                                shouldValidate: true,
                                                                shouldTouch: true,
                                                                shouldDirty: true
                                                            });
                                                            return;
                                                        }

                                                        const location = marshallerLocations.find(location => location.vendor_marshaller_location_id === value);
                                                        setShowSkippedAlert(false);
                                                        setValue('marshallingVendorId', location.vendor_marshaller_id);
                                                        setValue('marshallingAddress', {
                                                            location: location?.vendor_name,
                                                            deliveryAddress: {
                                                                title: location?.vendor_name,
                                                                address1: location?.address_1,
                                                                address2: location?.address_2,
                                                                address3: location?.address_3,
                                                                city: location?.city,
                                                                state: location?.state_code,
                                                                zip: location?.postal_code,
                                                                country: location?.iso_country_code_2 || 'USA',
                                                            },
                                                            pointOfContact: {
                                                                firstName: location?.first_name,
                                                                lastName: location?.last_name,
                                                                email: location?.email_address,
                                                                phone: {
                                                                    countryCode: location?.contact_phone_country_code,
                                                                    number: location?.contact_phone,
                                                                    extension: location?.contact_phone_extension,
                                                                }
                                                            },
                                                            telematicsInstaller: location?.is_telematics_installer,
                                                            acceptingElectricVehicles: location?.accept_zev,
                                                        }, {
                                                            shouldValidate: true,
                                                            shouldTouch: true,
                                                            shouldDirty: true
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        ]
                    },
                    {
                        dependentOn: 'location',
                        dependentValue: (value) => value && value !== '-1',
                        altStyle: 'alt-blue-border',
                        gap: '30px',
                        sections: [
                            {
                                fieldLayout: 'horizontal',
                                fields: [
                                    {
                                        label: "Marshalling location",
                                        id: 'marshallingAddress.location',
                                        type: 'readonly',
                                    },
                                    {
                                        label: 'Delivery address',
                                        id: 'marshallingAddress.deliveryAddress',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.AddressModifier(),
                                                rawModifierValue: true,
                                            }
                                        }
                                    },
                                    {
                                        label: 'Point of contact',
                                        id: 'marshallingAddress.pointOfContact',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.ContactModifier(),
                                                rawModifierValue: true,
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                fieldLayout: 'horizontal',
                                fields: [
                                    {
                                        label: "Telematics installer",
                                        id: 'marshallingAddress.telematicsInstaller',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.BooleanYesNoModifier(),
                                                rawModifierValue: true,
                                            }
                                        }
                                    },
                                    {
                                        label: 'Accepting electric vehicles',
                                        id: 'marshallingAddress.acceptingElectricVehicles',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.BooleanYesNoModifier(),
                                                rawModifierValue: true,
                                            }
                                        }
                                    },
                                    {}
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                header: 'License Plate Delivery Address',
                dependentOn: 'marshallingAddress.deliveryAddress.country',
                dependentValue: (value) => value === '' || value === 'US' || value === 'PR',
                element: {
                    header: {
                        style: {
                            color: '#005EA2',
                            fontSize: '16px',
                            fontWeight: '900',
                            textTransform: 'uppercase',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #DFE1E2',
                            marginBottom: '0px',
                        }
                    }
                },
                gap: '40px',
                sections: [
                    {
                        sections: [
                            {
                                component: () => {
                                    if (!showSkippedAlert) return null;

                                    return (
                                        <Alert
                                            type='warning'
                                            heading='Delivery address skipped.'
                                        >
                                            Make sure to select Marshalling locations for delivery.
                                        </Alert>
                                    )
                                }
                            },
                            {
                                sections: [
                                    {
                                        gap: '40px',
                                        fields: [
                                            {
                                                id: 'licenseDelivery',
                                                type: 'radio',
                                                options: [
                                                    {
                                                        label: 'Same as delivery address',
                                                        value: 'same',
                                                    },
                                                    {
                                                        label: 'Ship to FMC/field office location',
                                                        value: 'ship',
                                                    }
                                                ],
                                            },
                                            {
                                                dependentOn: 'licenseDelivery',
                                                dependentValue: 'ship',
                                                dependentWaitType: 'hidden',
                                                label: 'FMC location',
                                                id: 'fmcLocation',
                                                type: 'combobox',
                                                options: [
                                                    { label: '-select-', value: '-1' },
                                                    ...fleetManagementCenters.map(fmc => ({ label: fmc.name, value: fmc.id }))
                                                        .sort((a, b) => a.label.localeCompare(b.label))
                                                ],
                                                element: {
                                                    control: {
                                                        className: 'full-width-combobox',
                                                        onChange: (value) => {
                                                            if (!value || value === '-1') {
                                                                return;
                                                            }

                                                            const fmc = fleetManagementCenters.find(fmc => fmc.id === value);
                                                            formRef.current.setValue('fieldOffices', fmc?.fieldOffices);
                                                            formRef.current.setValue('fieldOffice', '-1');
                                                            formRef.current.setValue('fmcOfficeLocationDetails', {
                                                                location: fmc.name,
                                                                deliveryAddress: {
                                                                    address1: fmc.address1,
                                                                    address2: fmc.address2,
                                                                    address3: fmc.address3,
                                                                    city: fmc.city,
                                                                    state: fmc?.stateCode,
                                                                    zip: fmc.zipCode,
                                                                }
                                                            });
                                                        }
                                                    }
                                                }
                                            },

                                            {
                                                dependentOn: 'fmcLocation',
                                                dependentValue: (value) => {
                                                    return fleetManagementCenters?.find(fmc => fmc.id === value)?.fieldOffices?.length > 1
                                                },
                                                dependentWaitType: 'hidden',
                                                label: 'Field office',
                                                id: 'fieldOffice',
                                                type: 'select',
                                                required: false,

                                                component: (props, useFormContext) => {
                                                    const { watch } = useFormContext();
                                                    const [fieldOfficeData, setFieldOfficeData] = useState();
                                                    const watchFmcLocation = watch('fmcLocation');
                                                    const watchfmcOfficeLocationDetails = watch('fmcOfficeLocationDetails')
                                                    useMemo(async () => {
                                                        if (!watchFmcLocation && watchFmcLocation === '-1') {
                                                            return;
                                                        }

                                                        const fmc = fleetManagementCenters.find(fmc => fmc.id === watchFmcLocation);
                                                        setFieldOfficeData([
                                                            { label: '-select-', value: '-1' },
                                                            ...fmc?.fieldOffices?.map(fmc => ({ label: fmc.name, value: fmc.id }))
                                                                .sort((a, b) => a.label.localeCompare(b.label))
                                                        ])
                                                    }, [watchFmcLocation]);

                                                    return ComboBoxControl(
                                                        {
                                                            ...props,
                                                            className: [],
                                                        },
                                                        {
                                                            ...props,
                                                            options: fieldOfficeData,
                                                            element: {
                                                                control: {
                                                                    onChange: (value) => {
                                                                        if (!value || value === '-1') {
                                                                            return;
                                                                        }

                                                                        const fieldOffice = fleetManagementCenters.find(fmc => fmc.id === watchFmcLocation)?.fieldOffices?.find(fo => fo.id == value);
                                                                        formRef.current.setValue('fmcOfficeLocationDetails', {
                                                                            location: watchfmcOfficeLocationDetails.location,
                                                                            fieldOffice: fieldOffice?.name,
                                                                            deliveryAddress: watchfmcOfficeLocationDetails.deliveryAddress,
                                                                            fieldOfficeDeliveryAddress: {
                                                                                address1: fieldOffice.address1,
                                                                                address2: fieldOffice.address2,
                                                                                address3: fieldOffice.address3,
                                                                                city: fieldOffice.city,
                                                                                state: fieldOffice?.stateCode,
                                                                                zip: fieldOffice.zipCode,
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    )
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        dependentOn: ['licenseDelivery', 'fmcLocation'],
                                        dependentValue: (value) =>  {
                                            return value[0] === 'ship' && value[1] !== '-1';
                                        },
                                        altStyle: 'alt-blue-border',
                                        gap: '30px',
                                        sections: [
                                            {
                                                fieldLayout: 'horizontal',
                                                fields: [
                                                    {
                                                        label: "FMC location",
                                                        id: 'fmcOfficeLocationDetails.location',
                                                        type: 'readonly',
                                                    },
                                                    {
                                                        label: 'Delivery address',
                                                        id: 'fmcOfficeLocationDetails.deliveryAddress',          
                                                        dependentOn: 'fieldOffice',
                                                        dependentWaitType: 'hidden',
                                                        dependentValue: (value) => {
                                                            return !value || value === '-1';
                                                        },
                                                        type: 'readonly',
                                                        element: {
                                                            control: {
                                                                modifier: ReadonlyModifier.AddressModifier(),
                                                                rawModifierValue: true,
                                                            }
                                                        }
                                                    },                                             
                                                    {
                                                        label: 'Delivery address',
                                                        id: 'fmcOfficeLocationDetails.fieldOfficeDeliveryAddress',          
                                                        dependentOn: 'fieldOffice',
                                                        dependentWaitType: 'hidden',
                                                        dependentValue: (value) => {
                                                            return value && value !== '-1';
                                                        },
                                                        type: 'readonly',
                                                        element: {
                                                            control: {
                                                                modifier: ReadonlyModifier.AddressModifier(),
                                                                rawModifierValue: true,
                                                            }
                                                        }
                                                    },
                                                ]
                                            }, {
                                                fieldLayout: 'horizontal',
                                                fields: [
                                                    {
                                                        label: "Field Office",
                                                        dependentOn: 'fieldOffice',
                                                        dependentWaitType: 'hidden',
                                                        dependentValue: (value) => {
                                                            return value && value !== '-1';
                                                        },
                                                        id: 'fmcOfficeLocationDetails.fieldOffice',
                                                        type: 'readonly',
                                                    },]
                                            }
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        dependentOn: 'licenseDelivery',
                        dependentValue: 'ship',
                        ...generatePointOfContactForm({
                            title: 'Point of Contact',
                            subSection: true,
                            countryCodes: countryCodeOptions,
                            idPrefix: 'licenseDeliverPoC',
                        })
                    },
                    {
                        dependentOn: 'licenseDelivery',
                        dependentValue: 'same',
                        gap: '30px',
                        sections: [
                            {
                                fieldLayout: 'horizontal',
                                fields: [
                                    {
                                        id: 'marshallingAddress.deliveryAddress',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.AddressModifier({
                                                    includeTitle: true
                                                }),
                                                rawModifierValue: true,
                                            }
                                        }
                                    },
                                    {
                                        label: 'Point of contact',
                                        id: 'marshallingAddress.pointOfContact',
                                        type: 'readonly',
                                        element: {
                                            control: {
                                                modifier: ReadonlyModifier.ContactModifier(),
                                                rawModifierValue: true,
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }]
}